@import "./_variables";

#header {
  transition: all 0.5s;
  background: #fff;
  z-index: 997;
  padding: 15px 0;
  border-bottom: 1px solid #e6f2fb;
}
#header.header-scrolled {
  border-color: #fff;
  box-shadow: 0px 2px 15px rgba(18, 66, 101, 0.08);
}
#header .logo {
  font-size: 18px;
  margin: 0;
  padding: 0;
  line-height: 1;
  letter-spacing: 0;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: #16507b;
}
#header .logo span {
  color: #217ec5;
}
#header .logo img {
  max-height: 40px;
}

.navbar {
  padding: 0;
}
.navbar-collapse .navbar-nav {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}
.navbar-collapse .navbar-nav li {
  position: relative;
}
.navbar-collapse .navbar-nav li a,
.navbar-collapse .navbar-nav li a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 10px 30px;
  font-size: 14px;
  font-weight: 600;
  color: #16507b;
  white-space: nowrap;
  transition: 0.3s;
}
.navbar-collapse .navbar-nav li a i,
.navbar-collapse .navbar-nav li a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}
.navbar-collapse .navbar-nav li a:hover,
.navbar-collapse .navbar-nav .active,
.navbar-collapse .navbar-nav .active:focus,
.navbar-collapse .navbar-nav li:hover > a {
  color: $color-primary;
}
.navbar-collapse .navbar-nav .filled,
.navbar-collapse .navbar-nav .filled:focus {
  background: $color-primary;
  padding: 8px 20px;
  border-radius: 4px;
  color: #fff;
  margin-left: 30px;
}
.navbar-collapse .navbar-nav .filled:hover,
.navbar-collapse .navbar-nav .filled:focus:hover {
  color: #fff;
  background: #3194db;
}
.navbar-collapse .navbar-nav .dropdown ul {
  display: block;
  position: absolute;
  left: 14px;
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
  border-radius: 4px;
}
.navbar-collapse .navbar-nav .dropdown ul li {
  min-width: 200px;
}
.navbar-collapse .navbar-nav .dropdown ul a {
  padding: 10px 20px;
  text-transform: none;
}
.navbar-collapse .navbar-nav .dropdown ul a i {
  font-size: 12px;
}
.navbar-collapse .navbar-nav .dropdown ul a:hover,
.navbar-collapse .navbar-nav .dropdown ul .active:hover,
.navbar-collapse .navbar-nav .dropdown ul li:hover > a {
  color: $color-primary;
}
.navbar-collapse .navbar-nav .dropdown:hover > ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}
.navbar-collapse .navbar-nav .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}
.navbar-collapse .navbar-nav .dropdown .dropdown:hover > ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}
@media (max-width: 1366px) {
  .navbar-collapse .navbar-nav .dropdown .dropdown ul {
    left: -90%;
  }
  .navbar-collapse .navbar-nav .dropdown .dropdown:hover > ul {
    left: -100%;
  }
}
@media (max-width: 992px) {
  .navbar-collapse .navbar-nav li a,
  .navbar-collapse .navbar-nav li a:focus {
    padding: 10px 0 10px 0px;
    transition: 0s;
  }
  .navbar-collapse .navbar-nav .filled,
  .navbar-collapse .navbar-nav .filled:focus {
    margin-left: 0px;
  }
}
.navbar-toggler-icon {
  color: #124265 !important;
  font-size: 22px !important;
}
